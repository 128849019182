






































































































import { SfTabs, SfButton } from '@storefront-ui/vue';
import {
  defineComponent,
  useRouter,
  useContext,
  ref,
  computed,
  useFetch,
} from '@nuxtjs/composition-api';
import { useAddresses } from '~/modules/customer/composables/useAddresses';
import userAddressesGetters from '~/modules/customer/getters/userAddressesGetters';
import SvgImage from '~/components/General/SvgImage.vue';
import UserAddressDetails from '~/components/UserAddressDetails.vue';
import { CustomerAddress } from '~/modules/GraphQL/types';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import { useCountrySearch } from '~/composables/useCountrySearch';
import type { Country } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'AddressesDetails',
  components: {
    SfTabs,
    SfButton,
    SvgImage,
    UserAddressDetails,
    SkeletonLoader,
  },
  setup() {
    const context = useContext();
    const router = useRouter();

    const userAddresses = ref<CustomerAddress[]>([]);
    const countries = ref<Country[]>([]);
    const { load, remove } = useAddresses();
    const { load: loadCountries } = useCountrySearch();

    const { fetch } = useFetch(async () => {
      const [addressesResponse, countriesResponse] = await Promise.all([load(), loadCountries()]);
      userAddresses.value = userAddressesGetters.getAddresses(addressesResponse);
      countries.value = countriesResponse;
    });

    const goToCreateAddressPage = () => router.push(
      context.localeRoute({ name: 'customer-addresses-details-new' }),
    );

    const goToEditAddressPage = (addressId: number) => router.push(
      context.localeRoute({ name: 'customer-addresses-details-edit', params: { addressId: String(addressId) } }),
    );

    const removeAddress = async (address: CustomerAddress) => {
      await remove({ address });
      fetch();
    };

    const addressesWithCountryName = computed(() => userAddresses.value.map((address) => ({
      ...address,
      countryName: countries.value
        .find(({ id }) => id === address.country_code)
        ?.full_name_locale ??
        address.country_code,
    })));

    return {
      countries,
      userAddresses,
      addressesWithCountryName,
      goToCreateAddressPage,
      goToEditAddressPage,
      removeAddress,
      getId: userAddressesGetters.getId,
      isDefault: userAddressesGetters.isDefault,
    };
  },
});
